import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavigationContext } from '../../context/navigationContext';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';
import { ButtonDropDown } from '../Buttons/ButtonDropdown';
import { SubSidebar } from '../SubSidebar';

export const SidebarItem = ({
	item,
	isSelectValue,
	setIsSelect,
	setDataSecondSidebar,
	dataSecondSidebar,
	open,
	setOpen,
}) => {
	const navigation = useContext(NavigationContext);
	const { t } = useCustomTranslation();
	const ref = useRef(null);

	const [defocus, setDefocus] = useState(false);

	// Determinar color del icono basado en si está seleccionado
	const color = isSelectValue === item?.url ? '!text-white' : '!text-white';

	// Cerrar el menú cuando se hace clic fuera de él
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (ref.current && !ref.current.contains(e.target)) {
				setDefocus(true);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	// Manejo del estado de apertura/cierre cuando pierde el foco
	useEffect(() => {
		if (defocus && open[item?.id]) {
			setDataSecondSidebar([]);
			setOpen((prevOpen) => ({ ...prevOpen, [item?.id]: false }));
			setDefocus(false);
		}
	}, [defocus, item?.id, open, setOpen, setDataSecondSidebar]);

	// Cerrar el submenú manualmente
	const handleClose = () => {
		setOpen((prevOpen) => ({ ...prevOpen, [item?.id]: false }));
	};

	// Manejo del clic en el botón
	const handleClick = () => {
		if (item?.childrends) {
			setIsSelect(item?.url);
			setDataSecondSidebar(item);

			// Alternar visibilidad del menú actual y cerrar los demás
			setOpen((prevOpen) =>
				Object.keys(prevOpen).reduce(
					(acc, key) => ({
						...acc,
						[key]: key === item?.id ? !prevOpen[key] : false,
					}),
					{}
				)
			);
		} else {
			navigation.selectTap(item.url, '', {});
			setIsSelect(item?.url);
		}
	};

	return (
		<li className='relative h-[90px] flex' ref={ref}>
			<SubSidebar
				subSidebarData={dataSecondSidebar}
				current={open[item?.id]}
				close={handleClose}
			/>
			<ButtonDropDown
				id='dropdownDefault'
				data-dropdown-toggle='dropdown'
				keyItem={item?.id}
				onClick={handleClick}
				className={`!justify-center flex-grow !font-h3-font-family !border-none hover:!bg-menu-bg-default ${
					open[item?.id] ? '!bg-menu-bg-default' : '!bg-transparent'
				}`}>
				<div className='flex h-full w-full flex-col'>
					<div className='flex w-full m-auto items-center content-center justify-center flex-col'>
						<FontAwesomeIcon className={color} size='2x' icon={item?.icon} />
						<p className={`${color} text-xs text-center`}>
							{getTranslation(t, item?.translate)}
						</p>
					</div>
				</div>
			</ButtonDropDown>
		</li>
	);
};
