import React, { useState, Fragment } from 'react';
import routes from '../../routes';
import { SidebarItem } from './sidebarItem';

export const Sidebar = () => {
	const [dataSecondSidebar, setDataSecondSidebar] = useState([]);
	const [isSelect, setIsSelect] = useState(false);

	// Estado para controlar qué elementos del sidebar están abiertos
	const [open, setOpen] = useState(
		routes.reduce((acc, item) => ({ ...acc, [item.id]: false }), {})
	);

	return (
		<ul className='flex h-full w-[90px] z-50 bg-[#0277bde6] drop-shadow-2xl flex-col'>
			{/* Espaciado superior */}
			<li className='h-10' />
			{routes.map((item, index) => (
				<Fragment key={item.id || index}>
					<SidebarItem
						item={item}
						isSelect={isSelect}
						setIsSelect={setIsSelect}
						setDataSecondSidebar={setDataSecondSidebar}
						dataSecondSidebar={dataSecondSidebar}
						open={open}
						setOpen={setOpen}
					/>
				</Fragment>
			))}
		</ul>
	);
};
